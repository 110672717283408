// Action sheet
$actionsheet-padding-x: null !default;
$actionsheet-padding-y: null !default;
$actionsheet-width: 360px !default;
$actionsheet-max-width: 100% !default;
$actionsheet-max-height: 50vh !default;

$actionsheet-border-width: 0px !default;
$actionsheet-border-radius: 0px !default;

$actionsheet-font-size: $font-size !default;
$actionsheet-font-family: $font-family !default;
$actionsheet-line-height: $line-height !default;

$actionsheet-bg: $bg-color !default;
$actionsheet-text: $component-text !default;
$actionsheet-border: $component-border !default;
$actionsheet-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12) !default;


// Actionsheet header
$actionsheet-header-padding-x: map-get( $spacing, 4 ) !default;
$actionsheet-header-padding-y: map-get( $spacing, 2 ) !default;
$actionsheet-header-border-width: null !default;
$actionsheet-header-font-size: null !default;
$actionsheet-header-font-family: null !default;
$actionsheet-header-line-height: null !default;

$actionsheet-header-bg: null !default;
$actionsheet-header-text: null !default;
$actionsheet-header-border: null !default;
$actionsheet-header-gradient: null !default;
$actionsheet-header-shadow: null !default;


// Actionsheet item
$actionsheet-item-min-height: 40px !default;
$actionsheet-item-padding-x: map-get( $spacing, 4 ) !default;
$actionsheet-item-padding-y: map-get( $spacing, 2 ) !default;
$actionsheet-item-border-width: 1px !default;
$actionsheet-item-spacing: 12px !default;

$actionsheet-item-icon-size: null !default;

$actionsheet-item-title-font-weight: null !default;
$actionsheet-item-title-text-transform: null !default;

$actionsheet-item-description-font-size: .875em !default;
$actionsheet-item-description-text: $subtle-text !default;

$actionsheet-item-hover-bg: $hovered-bg !default;
$actionsheet-item-hover-text: null !default;
$actionsheet-item-hover-border: null !default;
$actionsheet-item-hover-gradient: null !default;
$actionsheet-item-hover-shadow: null !default;

$actionsheet-item-focus-bg: null !default;
$actionsheet-item-focus-text: null !default;
$actionsheet-item-focus-border: null !default;
$actionsheet-item-focus-gradient: null !default;
$actionsheet-item-focus-shadow: $focused-shadow !default;

$actionsheet-item-disabled-bg: null !default;
$actionsheet-item-disabled-text: null !default;
$actionsheet-item-disabled-border: null !default;
$actionsheet-item-disabled-gradient: null !default;
$actionsheet-item-disabled-shadow: null !default;
