@include exports("action-sheet/layout") {

    .k-actionsheet-container {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        overflow: hidden;
    }

    // Actionsheet
    .k-actionsheet {
        padding: $actionsheet-padding-y $actionsheet-padding-y;
        width: $actionsheet-width;
        max-width: $actionsheet-max-width;
        max-height: $actionsheet-max-height;
        border-width: 0;
        border-style: solid;
        border-color: transparent;
        box-sizing: border-box;
        font-size: $actionsheet-font-size;
        font-family: $actionsheet-font-family;
        line-height: $actionsheet-line-height;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        z-index: 10002;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }
    .k-actionsheet-fullscreen {
        max-height: 100%;
        height: 100%;
    }


    // Actionsheet header
    .k-actionsheet-header {
        padding: $actionsheet-header-padding-y $actionsheet-header-padding-x;
        border-width: 0;
        border-bottom-width: if( $actionsheet-header-border-width, $actionsheet-header-border-width, null );
        border-style: solid;
        border-color: transparent;
        box-sizing: border-box;
        font-size: $actionsheet-header-font-size;
        font-family: $actionsheet-header-font-family;
        line-height: $actionsheet-header-line-height;
        flex: none;
    }


    // Actionsheet titlebar
    .k-actionsheet-titlebar {
        @extend .k-actionsheet-header !optional;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .k-actionsheet-title {
        flex: 1;
    }
    .k-actionsheet-actions {
        flex: none;
    }


    // Actionsheet content
    .k-actionsheet-content {
        flex: 1;
        overflow: auto;
    }


    // Actionsheet footer
    .k-actionsheet-footer {
        flex: none;
    }


    // Actionsheet items
    .k-actionsheet-items {
        margin: 0;
        padding: 0;
        list-style: none;
    }


    // Actionsheet item
    .k-actionsheet-item {
        padding: 0;
        box-sizing: border-box;
    }
    .k-actionsheet-action {
        margin: 0;
        padding: $actionsheet-item-padding-y $actionsheet-item-padding-x;
        min-height: $actionsheet-item-min-height;
        box-sizing: border-box;
        color: inherit;
        text-decoration: none;
        outline: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: $actionsheet-item-spacing;
    }
    .k-actionsheet-item-icon {
        font-size: $actionsheet-item-icon-size;
    }
    .k-actionsheet-item-text {
        display: flex;
        flex-flow: column nowrap;
    }
    .k-actionsheet-item-title {
        font-weight: $actionsheet-item-title-font-weight;
        text-transform: $actionsheet-item-title-text-transform;
    }
    .k-actionsheet-item-description {
        font-size: $actionsheet-item-description-font-size;
    }


    // Actionsheet separator
    .k-actionsheet > .k-hr {
        margin: 0;
        border-color: inherit;
    }


    // Action sheet position
    .k-actionsheet-top {
        @include border-bottom-radius( $actionsheet-border-radius );
        border-width: if( $actionsheet-border-width, $actionsheet-border-width, null );
        border-top-width: if( $actionsheet-border-width, 0, null );
        top: 0;
        left: 50%;
        transform: translateX( -50% );
    }
    .k-actionsheet-bottom {
        @include border-top-radius( $actionsheet-border-radius );
        border-width: if( $actionsheet-border-width, $actionsheet-border-width, null );
        border-bottom-width: if( $actionsheet-border-width, 0, null );
        bottom: 0;
        left: 50%;
        transform: translateX( -50% );
    }
    .k-actionsheet-left {
        @include border-right-radius( $actionsheet-border-radius );
        border-width: if( $actionsheet-border-width, $actionsheet-border-width, null );
        border-left-width: if( $actionsheet-border-width, 0, null );
        left: 0;
        top: 50%;
        transform: translateY( -50% );
    }
    .k-actionsheet-right {
        @include border-left-radius( $actionsheet-border-radius );
        border-width: if( $actionsheet-border-width, $actionsheet-border-width, null );
        border-right-width: if( $actionsheet-border-width, 0, null );
        right: 0;
        top: 50%;
        transform: translateY( -50% );
    }


    // Action sheet in popup
    .k-animation-container > .k-actionsheet {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        transform: none;
    }


    // Adaptive action sheet
    .k-adaptive-actionsheet {
        max-width: 100%;
        width: 100%;

        // TMP: this should be moved to action sheet
        display: flex;
        flex-flow: column nowrap;

        .k-calendar {
            margin-inline: auto;
            border-width: 0;
            display: flex;
        }

        .k-timeselector {
            height: 100%;
            border-width: 0;
            overflow: hidden;

            .k-time-part {
                display: contents;
            }

            .k-time-list-wrapper {
                height: 100%;
            }
        }
    }

}
