@import '@mri/scss-all/scss/variables/base/_colors.scss';
@import '../../../../../libs/shared/styles/src/lib/mri-kendo-custom.scss';

.k-outline.ag-k-button--dark {
  &,
  &:active,
  &:focus {
    color: $mri-light-text-color;
    border-color: $mri-light-line-color;
  }
  &:enabled:hover {
    background-color: transparentize($mri-light-interactive-color, 0.9);
    color: $mri-light-text-color;
    border-color: $mri-light-line-color;
  }
}

/**
  prevent grid row to increase in height due to long text triggering text wrapping
  this is critical when virtual scrolling has been enabled for grid

  todo: remove this once added to @mri/kendo-theme
*/
kendo-grid[scrollable='virtual'] tbody td {
  white-space: nowrap;
}

// todo: remove this once added to @mri/kendo-theme
.k-header.mri-k-header--allow-overflow > .k-link {
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}

/**
Override the width size to auto for drawer
*/
@media screen and (min-width: 600px) {
  .mri-drawer {
    width: auto;
  }
}

/* Overriding the z-index of the kendo-popup for shell, due to z-index of the loading component set to 1000
   Problem: The loading component overlays kendo-popup(having z-index: 71 by default) making it unusable*/
.shell-footer-menu > kendo-popup {
  z-index: 1000;
  max-width: 450px;
}

/* set the icon to center in kendodropdown button(icon only), due to
.k-button-icon:after { margin-left:8px } by default */
.access-links > .k-button-icon {
  padding-left: 12px !important;
}

/*Icon in dashboard has an oval shape because the line-height property 
for medium sized icons was set to 1.4285714286 
and there is no padding in kendo icons when mri-icons have a padding of 8px*/
.dashboard-icon > button {
  line-height: 1 !important;
  padding: 8px;
}

//TODO: Remove this after it has been implemented in the latest mri package
// dialog-actions have a margin of -5em which is breaking the dialog UI
//previously these classes had a padding of 16px which is not present currently
.dialog-actions.k-window-actions {
  margin: 0;
  padding: 16px;
}

//client switch search doesn't have width property
.client-switch-search > .k-form-field-wrap {
  width: 100%;
}

//TODO: Remove this after it has been implemented in the latest mri package
//The 3 sections of notification icon, content and close button are in a
//vertical format and are changed to horizontal here
.k-notification {
  gap: 1em;
}

//Required icon not being show in the latest kendo package
input.k-textbox.k-required.mri-k-show-validation {
  padding-right: 25px;
  background-image: $required-icon;
}

.shell-footer-menu > * > kendo-icon-wrapper {
  line-height: 1px;
}

//The notification appears below the loading screen which has a z-index of 1000,
//increasing the z-index of the notification group to fix this.
.k-notification-group {
  z-index: 1000;
}

/* There is a cursor pointer attribute that is inherited which causes a blue background 
to appear in an unrelated location after clicking anywhere in the filter*/
.filter-list {
  cursor: unset;
}

/* Filter on the actionsheet doesn't take up the entire space and doesn't look ideal.*/
.filter * .k-actionsheet-filter {
  flex: 1;
}

/* The overlay on the actionsheet has a z-index of 10001 which ends up covering the 
actionsheet rendering us unable to use the actionsheet*/
.filter * .k-overlay {
  z-index: 0;
}
