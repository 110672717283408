@include exports("action-sheet/theme") {

    // Actionsheet
    .k-actionsheet {
        @include fill(
            $actionsheet-text,
            $actionsheet-bg,
            $actionsheet-border
        );
        @include box-shadow( $actionsheet-shadow );
    }


    // Actionsheet header
    .k-actionsheet-header {
        @include fill(
            $actionsheet-header-text,
            $actionsheet-header-bg,
            $actionsheet-header-border,
            $actionsheet-header-gradient
        );
        @include box-shadow( $actionsheet-header-shadow );
    }


    // Actionsheet items
    .k-actionsheet-items {}


    // Actionsheet item
    .k-actionsheet-item {}


    // Actionsheet item description
    .k-actionsheet-item-description {
        @include fill( $color: $actionsheet-item-description-text );
    }


    // Actionsheet action
    .k-actionsheet-action {


        // Hover state
        &:hover,
        &.k-hover {
            @include fill(
                $actionsheet-item-hover-text,
                $actionsheet-item-hover-bg,
                $actionsheet-item-hover-border,
                $actionsheet-item-hover-gradient
            );
            @include box-shadow( $actionsheet-item-hover-shadow );
        }


        // Focus state
        &:focus,
        &.k-focus {
            @include fill(
                $actionsheet-item-focus-text,
                $actionsheet-item-focus-bg,
                $actionsheet-item-focus-border,
                $actionsheet-item-focus-gradient
            );
            @include box-shadow( $actionsheet-item-focus-shadow );
        }


        // Disabed state
        &:disabled,
        &.k-disabled {
            @include fill(
                $actionsheet-item-disabled-text,
                $actionsheet-item-disabled-bg,
                $actionsheet-item-disabled-border,
                $actionsheet-item-disabled-gradient
            );
            @include box-shadow( $actionsheet-item-disabled-shadow );
        }
    }

}
